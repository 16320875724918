
.homepage{
    padding-left: 8rem;
    padding-right: 8rem;
}

.homepage__container{
    background-image:  linear-gradient(to right,rgba(249, 250, 251, 0.71),rgba(249,226,196,0.5), rgba(248,203,160,0.5),rgba(249, 250, 251, 0.71));
    padding-bottom: 5rem;
    padding-left: 0rem !important;
}


.homepage__content{
    margin-top:2rem;

}
.homepage__text{
    width: 100%;
font-family: 'inter';
color: #222222;
font-style: normal;
font-weight: 700;
font-size: 6rem;
line-height: 116.5%;
}

.homepage__subtext{

display:flex;
margin-left:70%;
box-sizing: border-box;
font-family: 'Ibarra Real Nova', serif;
font-style: normal;
font-weight: 600;
font-size: 26.3486px;
line-height: 33px;
/* identical to box height */
text-align: center;
color: #2C374F;
}

.homepage__content__social{
    display:grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin-top:4rem;
   
}

.homepage__content__social--content{
    display:flex;
    align-items: center;
    text-decoration: none;
}

.homepage__content__social--content--text{
font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 21px;
color: #222222;
margin-left:0.5rem
}


/*Media Queries*/
@media (min-width: 481px) and (max-width: 767px) {
    .homepage{
        padding-left: 0rem;
        padding-right: 0rem
    }
    .homepage__text{
        font-weight: 700;
        font-size: 4rem;
    }
    .homepage__subtext{
        font-weight: 600;
        font-size: 1rem
    }
    .homepage__content__social--content--text{
        font-size: 0.9rem;
    }
}


@media (max-width: 480px)  {
    .homepage{
        padding-left: 2rem;
        padding-right: 2rem;
    }
    .homepage__text{
        font-weight: 600;
        font-size: 2rem;
    }
    .homepage__subtext{
        font-weight: 600;
        font-size: 1rem
    }
    .homepage__content__social--content--text{
        font-size: 0.9rem;
    }
    .homepage__content__social{
       grid-template-columns: 1fr 1fr 1fr;
    }
}