.aboutSection{
    margin-top:2rem;
    height:100%;
    position:relative;
    height:100%

}
.aboutSection__heading{
height: 120px;
font-family: 'Ibarra Real Nova';
font-style: normal;
font-weight: 700;
font-size: 64px;
line-height: 116.5%;
text-align: center;
color: #2C374F;
/* margin-top: 1rem; */
}

/* .aboutSection__card{
    position:absolute;
    margin-left: -12%;
    text-align: center;
    margin-top:5rem;
} */

 .aboutSection__one{
    animation-name: cardone;
    animation-duration: 5s;
    animation-delay: 2s;
    animation-fill-mode: forwards;
    transition: all 1s ease-out;

 }

.aboutSection__two{
    animation-name: cardtwo;
    animation-duration: 5s;
    animation-delay: 2s;
    animation-fill-mode: forwards;
    transition: all 1s ease-out;

}

.aboutSection__four{
    animation-name: cardfour;
    animation-duration: 5s;
    animation-delay: 2s;
    animation-fill-mode: forwards;
    transition: all 1s ease-out;


}

.aboutSection__five{
    animation-name: cardfive;
    animation-duration: 5s;
    animation-delay: 2s;
    animation-fill-mode: forwards;
    transition: all 1s ease-out;


}



@keyframes cardone{
    0%   {}
   100% {transform:translate(-155%)}

}
@keyframes cardtwo{
    0%   {}
    100% {transform:translate(-80%)}

} 


@keyframes cardfour{
    0%   {}
   100% {transform:translate(150%)}

}
@keyframes cardfive{
    0%   {}
    100% {transform:translate(80%)}

} 

.test{
    display: none;
}



/*Media Queries*/
@media (min-width: 481px) and (max-width: 767px) {
    .aboutSection__heading{
        width: 90%;
        height: 15%;
        font-size: 3rem;
        font-weight: 700;
        margin-left:3rem;
   
    }
}


@media (max-width: 480px)  {
    .aboutSection__heading{
        width: 100%;
        height: 15%;
        font-size: 2rem;
        margin-left: 2rem;
    }
}