.card-medium {
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0px solid rgba(0, 0, 0, 0);
  border-radius: .25rem;
  margin-bottom: 1.5rem;
  box-shadow: 0 2px 6px 0 rgb(218 218 253 / 65%), 0 2px 6px 0 rgb(206 206 238 / 54%);
}

  .card-body{-ms-flex:1 1 auto;flex:1 1 auto;padding:1.25rem}

  .mb-3
  {margin-bottom:1rem!important}

  .mx-auto{margin-right:auto!important}
 
  .mr-5,.mx-5{margin-right:3rem!important;
  width: 50% !important;}

  .card-body{-ms-flex:1 1 auto;flex:1 1 auto;padding:1.25rem}

  .btn-outline-success{color:#28a745;background-color:transparent;background-image:none;border-color:#28a745}
  .btn-outline-success:hover{color:#fff;background-color:#28a745;border-color:#28a745}
  .btn-outline-success.focus,.btn-outline-success:focus{box-shadow:0 0 0 .2rem rgba(40,167,69,.5)}
  .btn-outline-success.disabled,.btn-outline-success:disabled{color:#28a745;background-color:transparent}
  .btn-outline-success:not(:disabled):not(.disabled).active,.btn-outline-success:not(:disabled):not(.disabled):active,.show>
  .btn-outline-success.dropdown-toggle{color:#fff;background-color:#28a745;border-color:#28a745}
  .btn-outline-success:not(:disabled):not(.disabled).active:focus,.btn-outline-success:not(:disabled):not(.disabled):active:focus,.show>
  .btn-outline-success.dropdown-toggle:focus{box-shadow:0 0 0 .2rem rgba(40,167,69,.5)}

  .container{width:100%;padding-right:15px;padding-left:15px;margin-right:auto;margin-left:auto;margin-top:auto;}
  @media (min-width:576px){.container{max-width:540px}}
  @media (min-width:768px){.container{max-width:720px}}
  @media (min-width:992px){.container{max-width:960px}}
  @media (min-width:1200px){.container{max-width:1140px}}

  .row{display:flex;
    margin-right:-15px;margin-left:-15px;
    margin-top:-5px;
  flex-direction: row;}

  .mt-5,.my-5{margin-top:0rem!important}

  .pt-5,.py-5{padding-top:0rem!important}

  .jumbotron{margin-bottom:1rem;background-color:#fbfdff;border-radius:.1rem}

  .text-center{text-align:center!important}

  .btn{display:inline-block;font-weight:400;text-align:center;white-space:nowrap;vertical-align:middle;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;border:1px solid transparent;padding:.375rem .75rem;font-size:1rem;line-height:1.5;border-radius:.25rem;transition:color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out}
  .btn:focus,.btn:hover{text-decoration:none}
  .btn.focus,.btn:focus{outline:0;box-shadow:0 0 0 .2rem rgba(0,123,255,.25)}
  .btn.disabled,.btn:disabled{opacity:.65}.btn:not(:disabled):not(.disabled){cursor:pointer}

  .display-4{font-size:3.5rem;font-weight:300;line-height:1.2}

  a.Link:link {
    color: #FA8C4F;
    background-color: transparent;
    text-decoration: none;
  }
  a.Link:visited {
    color: rgb(42, 123, 42);
    background-color: transparent;
    text-decoration: none;
  }
  a.Link:hover {
    color: green;
    background-color: transparent;
    text-decoration: underline;
  }
  a.Link:active {
    color: yellow;
    background-color: transparent;
    text-decoration: underline;
  }

  a.Link:visited {
    color: #FA8C4F;
    background-color: transparent;
    text-decoration: none;
    font-weight: bolder;
    font-size: larger;

  }

  p.LinkText{
    font-size: normal;

  }
  

  .recentepisodes__heading_blog{
    height: 75px;
    font-family: 'Ibarra Real Nova';
    font-style: normal;
    font-weight: 700;
    font-size: 64px;
    line-height: 116.5%;
    text-align: center;
    color: #2C374F;
    margin-bottom:2rem;
    margin-top:2rem;
}
 
@media (max-width: 480px)  {
  .row{
    
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center ;
    
  }
  
    .mr-5,.mx-5{margin-right:3rem!important;
      width: 80% !important;}
    .row{
      
      padding: 1.25rem;
    }
    .recentepisodes__heading_blog{
      
      margin-bottom:6rem;
      
  }
    
}


