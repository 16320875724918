.investorsection{
    display: grid;
    justify-content: center;
    justify-items: center;
    grid-row-gap: 1rem;
    margin-top:3rem;
}
.investorsection__heading{
    font-family: 'Ibarra Real Nova';
font-style: normal;
font-weight: 700;
font-size: 64px;
line-height: 116.5%;
text-align: center;
color: #2C374F;
}