.recentepisodes{
    /* height:100%; */
    margin-top:2rem
}


.recentepisodes__heading{
    height: 9rem;
    font-family: 'Ibarra Real Nova';
    font-style: normal;
    font-weight: 700;
    font-size: 64px;
    line-height: 116.5%;
    text-align: center;
    color: #2C374F;
    margin-bottom:2rem
}
.recentepisodes__content__container{
    max-height:50rem;
    overflow-x:hidden;
    overflow-y:scroll;
}
.recentepisodes__content{
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items:center;
    align-content:space-evenly;
    grid-row-gap: 3rem;
    padding-bottom:2rem;
}
.recentepisodes__content a{
    text-decoration:none
}


@media (min-width: 481px) and (max-width: 767px) {
    .recentepisodes{
        height: 100%;
    }
   .recentepisodes__content{
    grid-template-columns: 1fr;
    margin-top:1rem;
    padding-left: 2rem;

   }
}


@media (max-width: 480px)  {
    .recentepisodes{
        height: 100%;
    }
    .recentepisodes__content{
        grid-template-columns: 1fr;
        margin-top:5rem;
        

      
       }
       .recentepisodes__heading{
           width:90%;
           font-size:3rem;
           margin-bottom:3rem;
           padding-left: 1rem;
           height: 9rem;
       }
   
}


@media (min-width: 1700px)  {
    .recentepisodes__content{
        grid-template-columns: 1fr 1fr 1fr;
      
    }
}


