.videoplayerpage{
    padding-left:2rem;
    background-image:  linear-gradient(to right,rgba(249, 250, 251, 0.71),rgba(249,226,196,0.5), rgba(248,203,160,0.5),rgba(249, 250, 251, 0.71));
    height:100%;
    padding-top:1rem
}
.videoplayerpage__backbuttonconatiner{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 3rem;
}
.videoplayerpage__backbutton{
 
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14.5404px;
    line-height: 160%;
  
    
    color: #4D4D4D;
}
.videoplayer{
    display: grid;
    grid-template-columns: 3fr 1fr;
    justify-items: stretch;
    text-align: center;
    margin-left: 15%;
    border-radius:1px solid red;
    border-radius:2rem;
    height:80vh;

}

.videoplayer__iframe {
    border-radius:3rem;
    height:100%;
    width: 100%;
    border:1px solid black
}


@media (min-width: 481px) and (max-width: 767px) {
    .videoplayer{
        height:100%
    }
    .videoplayer__iframe{
     height:100%;
 }

  }
  
  
  @media (max-width: 480px)  {
     
      .videoplayer{
          height:100%;
          margin-left: 0%;

      }
      .videoplayer__iframe{
        height:100%;
        width: 120%;
}

}
   