.scene {
    min-width: 15rem;
    min-height: 15rem;
    /* border: 1px solid #CCC; */
    perspective: 600px;
    position:absolute;
    margin-left: -12%;
    text-align: center;
    /* margin-top:1rem; */
  }
  
  .card {
    width: 100%;
    height: 100%;
    transition: transform 1s;
    transform-style: preserve-3d;
    cursor: pointer;
    position: relative;
  }
  
  .card:hover {
    transform: rotateY(180deg);
  }
  
  .card:hover .backText{
      transform: rotateY(180deg);
      visibility: visible;
    }
    .card:hover .frontText{
      visibility: hidden;
    }
  
  
  .card__face {
    position: absolute;
    width: 100%;
    height: 100%;
    line-height: 260px;
    color: white;
    text-align: center;
    font-weight: bold;
    font-size: 2rem;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  
  /* .card__face--front {
    background: red;
  } */
  
  .card__face--back {
  
    transform: rotateY(180deg);
  }
  
  .cardImage{
      height:18rem;
      width:18rem
  }
 
  .frontText{
position: absolute;
top:7rem;
left: 6rem;
font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 1.5rem;
line-height: 150%;
display: Grid;
align-content: center;
justify-content: space-around;
justify-items: center;
align-items: center;
color: #FFFFFF;

  }


  .backText{
      position: absolute;
      right:3rem;
      top:4.5rem;
      backface-visibility: hidden;
      visibility: hidden;
      font-family: 'Poppins';
      font-style: normal;
     font-weight: 300;
     font-size: 1rem;
     width:80%;
     line-height: 150%;
     color: #FFFFFF;
     display: flex;
     text-align: center;
     justify-content:center;
     align-items:center;
     flex-wrap: wrap;
     }


.aboutSection__1{
    animation-name: cardone;
    animation-duration: 5s;
    animation-delay: 2s;
    animation-fill-mode: forwards;
    transition: all 1s ease-out;
}

.aboutSection__2{
    animation-name: cardtwo;
    animation-duration: 5s;
    animation-delay: 2s;
    animation-fill-mode: forwards;
    transition: all 1s ease-out;

}

.aboutSection__4{
    animation-name: cardfour;
    animation-duration: 5s;
    animation-delay: 2s;
    animation-fill-mode: forwards;
    transition: all 1s ease-out;


}

.aboutSection__5{
    animation-name: cardfive;
    animation-duration: 5s;
    animation-delay: 2s;
    animation-fill-mode: forwards;
    transition: all 1s ease-out;
}



@keyframes cardone{
    0%   {}
   100% {transform:translate(-195%)}

}
@keyframes cardtwo{
    0%   {}
    100% {transform:translate(-100%)}

} 


@keyframes cardfour{
    0%   {}
   100% {transform:translate(190%)}

}
@keyframes cardfive{
    0%   {}
    100% {transform:translate(100%)}

} 


@media (min-width: 481px) and (max-width: 767px) {
    .scene{
        display: none !important;
        overflow-x: hidden;
    } 
    .card__face{
      display:none !important;

    }
    .frontText,
    .backText{
      display:none
    }
  }
  
  
  @media (max-width: 480px)  {
    .scene{
      display: none !important;
      overflow-x: hidden;
  } 
  .card__face{
    display:none !important;
  }
  .frontText,
  .backText{
    display:none

    
  }
  }