.navbar{
    display: grid;
    grid-template-columns: 20% 30% 50%;
    align-items: center;
    padding-left:2rem;
    padding-top:1rem
}


.navbar__left--image{
  width:6rem;
  height:5rem

}

.navbar__right{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
   font-family: 'Poppins';
   font-style: normal;
   font-weight: 600;
   font-size: 16.7724px;
   line-height: 160%;
   color: #000000;
}

.navbar__right__link{
  cursor: pointer;
}


.navbar__popupmenu{
    display: none;
}


@media only screen and (min-width: 320px) and (max-width: 768px) {
    .navbar{
        display: flex;
        justify-content:space-between
    }
    .navbar__middle {
      display: none;
    }
    .navbar__right{
      display: none;
    }
    .navbar__popupmenu{
      display:block;
      margin-right: 1rem;
    }
  
  }

  .navbar__sendsubscribe__hidden{
    position: absolute !important;
    visibility:hidden !important;
  }

  .navbar__sendsubscribe__visible{
    position: none;
    visibility:visible;
  }