.footer__container {
    width:100%;
    height: 100%;
    background-color: #0b0d17;
    padding-top: 2rem;
    padding-bottom:2rem ;
    color: white;
    box-sizing: border-box;
    margin-top: 1rem;
  }
  
  .footer__icon{
    width:6rem;
    height:5rem;
  }
  
  .footer__link {
    text-decoration: none;
    color: white;
    margin-left: 1rem;
  }
  