.wrapper
{
  width: 100%;
    min-height: 100vh;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 15px;
    background: #faebd7;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

*, ::after, ::before {
  box-sizing: inherit;
}

body, html {
  height: 100%;
  font-family: Poppins-Regular,sans-serif;
}

body {
  margin: 0;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  
}

html {
  box-sizing: border-box;
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
}

*, ::after, ::before {
  box-sizing: inherit;
}
*, ::after, ::before {
  box-sizing: inherit;
}


.form-container{
  width: 920px;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  padding: 62px 55px 90px;
}

div {
  display: block;
}

.feedback-heading 
{
    display: block;
    width: 100%;
    font-family: 'Sofia Sans', sans-serif;
    font-size: 39px;
    color: #333;
    line-height: 1.2;
    padding-bottom: 59px;
    text-align: center;

}

.Email-text-container {
  width: 100%;
  position: relative;
  border: 1px solid #e6e6e6;
  border-radius: 13px;
  padding: 10px 30px 9px 22px;
  margin-bottom: 20px;
  background-color: #f7f7f7;
}

.Email-text-heading {
font-family: 'Montserrat', sans-serif;
  font-size: 10px;
  color: #393939;
  line-height: 1.5;
}
input.Email-text 
{
  height: 30px;
}
.Email-text {
  display: block;
  width: 100%;
  background: 0 0;
  font-family: 'Lato', sans-serif;

  font-size: 18px;
  color: #555;
  line-height: 1.2;
  padding-right: 15px;

}
.Email-text::-webkit-input-placeholder {
font-family: 'Montserrat', sans-serif;
}

.Email-text:-ms-input-placeholder {
  font-family: 'Montserrat', sans-serif;}

.Email-text:-moz-placeholder {
  font-family: 'Montserrat', sans-serif;}

.Email-text::-moz-placeholder {
  font-family: 'Montserrat', sans-serif;}

input {
  outline: none;
  border: none;
}

textarea.Email-text {
  min-height: 120px;
  padding-top: 9px;
  padding-bottom: 13px;
}

textarea {
  outline: none;
  border: none;
}

textarea {
  overflow: auto;
  resize: vertical;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

.btn-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 20px;
  width: 100%;
}

.btn-guest {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  width: 100%;
  height: 50px;
  background-color: #333;
  border-radius: 25px;
  font-family: Montserrat-Medium;
  font-size: 16px;
  color: #fff;
  line-height: 1.2;
  -webkit-transition: all .4s;
  -o-transition: all .4s;
  -moz-transition: all .4s;
  transition: all .4s;
}

button {
  outline: none!important;
  border: none;
  background: 0 0;
}

[type=reset], [type=submit], button, html [type=button] {
  -webkit-appearance: button;
}

button, select {
  text-transform: none;
}

button, input {
  overflow: visible;
}

[role=button], a, area, button.btn-guest, input, label, select, summary, textarea {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

.btn-guest i {
  -webkit-transition: all .4s;
  -o-transition: all .4s;
  -moz-transition: all .4s;
  transition: all .4s;
}

