.getintouch{
    height:20rem;
    width:100%;
    background-image:url("../../assests/getIntouchbg.svg"),url("../../assests/getIntouchbg2.svg");
    background-color: antiquewhite;
    background-position: right bottom, right bottom;
    background-repeat: no-repeat, no-repeat;
    background-size: auto;
    display:grid;
   grid-template-columns: 1fr 2fr;
   align-items: center;
    justify-items: center;
   margin-top: 4rem;
}

.getintouch__content{
    display: grid;
    grid-row-gap:1rem;
}
.getintouch__content__description{
width: 100%;

font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 42px;
line-height: 150%;
/* or 63px */

display: flex;
align-items: center;

color: #222222;
}

.getintouch__content__minidescription{
    display:flex;
    flex-direction: row;
    /* width:75%; */
    justify-content: space-between;
    align-items: center
}

.getintouch__content__minidescription--text{
font-family: 'Nanum Brush Script';
font-style: normal;
font-weight: 400;
font-size: 1.5rem;
line-height: 150%;
color: #222222;
}

@media (min-width: 481px) and (max-width: 767px) {
    .getintouch__image{
        height:25%;
        width:25%;
    }
  
 
}


@media (max-width: 480px)  {
    .getintouch{
        height:10rem;
        width:100%
    }
    .getintouch__image{
        height:100%;
        width:90%;
    }
    .getintouch__content__description{
        font-size:1rem
    }
    .getintouch__content__minidescription{
      font-size: 0.58rem;
    }
    .getintouch__content__minidescription--text{
        font-size:0.8rem
    }
   
}
