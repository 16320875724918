.videoplayercontent{
    height:100%;
    padding-right:6rem;
    padding-left:6rem;
    background-image:  linear-gradient(to right,rgba(249, 250, 251, 0.71),rgba(249,226,196,0.5), rgba(248,203,160,0.5),rgba(249, 250, 251, 0.71));
    display:flex;
    flex-direction: column;
    align-items: center;
}

.videoplayercontent__heading{

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 2rem;
    line-height: 140%;
    letter-spacing: -0.02em;
    color: #222222;
    margin-top:3rem;
    margin-bottom: 1rem;
}

.videoplayercontent__description{
   
   font-family: 'Poppins';
   font-style: normal;
   font-weight: 500;
   font-size: 24px;
   line-height: 160%;
/* or 38px */


/* Davys Grey */

color: #4D4D4D;

}

.videoplayercontent__available__container{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-top:2rem;
    margin-bottom:2rem
}
.videoplayercontent__available{

    /* width: 643.97px;
    height: 60px; */
    margin-bottom: 0.4rem;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 150%;
    display: flex;
    align-items: center;
    color: #222222;
}


@media (min-width: 481px) and (max-width: 767px) {
    .videoplayercontent__heading{
        font-size: 1.5rem;
    }
    .videoplayercontent{
        padding-left:4rem;
    }

  }
  
  
  @media (max-width: 480px)  {
    .videoplayercontent__heading{
        font-size: 1.2rem;
    }
    .videoplayercontent{
        padding-left:4rem;
        padding-right:4rem;
    }
    .videoplayercontent__description{
        font-size: 1rem;
    }
    .videoplayercontent__available{
        font-size: 1.2rem;
    }


}
   