/* Footercard styles */
.aboutsection__footer{
  position: relative;
  display: grid;
  grid-template-columns: 1fr 2fr;
  padding:2rem;
  align-content: center;
  /* justify-content: space-around; */
  /* justify-items: end; */
  align-items: center;
width: 100%;
height: 14rem;
margin-top:8%;
background: #F1F1F1;
box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.02), 0px 2px 4px rgba(0, 0, 0, 0.08);
border-radius: 10px;
background-image: url(../assests/Vector1.svg),url(../assests//Vector2.svg);
background-position: right bottom, left top;
background-repeat: no-repeat, no-repeat;
background-size: auto;

}
.aboutsection__footer--image{
  width:100%;
}
.aboutsection__footer--crown{
  max-width:30%;
  position: absolute;
  left:40%;
  top:-20%
}





.aboutsection__footer--text{
 width: 90%;
height: 136px;
font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 140%;
text-align: center;
letter-spacing: -0.02em;
color: #000000;
}




/* recent episodes styles */

.recentepisodes__card{
box-sizing: border-box;
width: 90%;
height: 15rem;
background: #FFFFFF;
box-shadow: 0px 0px 6.23158px rgba(0, 0, 0, 0.02), 0px 2.07719px 4.15439px rgba(0, 0, 0, 0.08);
border-radius: 12px;
display:grid;
grid-template-columns: 2fr 3fr;
text-decoration: none !important;
}

.recentepisodes__card__content{
  width:100%;
  height:100%;
  display:grid;
  align-content: space-evenly
}
.recentepisodes__card__content--container{
  padding-bottom: 0.8rem;
  margin-right:0.2rem;
  border-bottom: 0.1rem black solid;
}

.recentepisodes__card__content--episode{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16.6175px;
    line-height: 160%;
    color: #FA8C4F;
}


.recentepisodes__card__content--heading{
  width:100%;
  height: 95%;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 140%;
  letter-spacing: -0.02em;
  color: #000000;
  text-overflow:ellipsis;

}

.recentepisodes__card__content--description{
width: 100%;
height: 95%;
font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 160%;
color: #4D4D4D;
text-overflow:ellipsis;
overflow:hidden;

display: -webkit-box !important;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
white-space: normal;
}





@media (min-width: 481px) and (max-width: 767px) {

  .aboutsection__footer{
    grid-template-columns: 1fr;
      width: 100%;
      margin-top:1rem;
      height:100%;   
     box-sizing: border-box;


  }
  .aboutsection__footer--image{
    height:10rem;
  
  }
  .aboutsection__footer--crown{
    position: absolute;
    left:47%;
    top:-6%;
    width:25%
  }
  .aboutsection__footer--text{
    height:100%;
    width:90%
  }

  .recentepisodes__card{
    max-width:95%;
    grid-template-columns: 1fr 3fr;
    margin-bottom: 1rem;

  }
   .recentepisodes__card__content--heading{
    font-size: 1rem;
  }
  
}


@media (max-width: 480px)  {
  .aboutsection__footer{
    grid-template-columns: 1fr;
    width: 100%;
    margin-top:-3rem;
    height:100%;
    box-sizing: border-box;
      /* height: 15%; */
     
  }
  .aboutsection__footer--image{
    height:10rem
  }
  .aboutsection__footer--crown{
    position: absolute;
    left:47%;
    top:-6%;
    width:25%
  }
  .aboutsection__footer--text{
    height:100%;
    width:90%
  }

  .recentepisodes__card{
    max-width:95%;
    grid-template-columns: 2fr 3fr;
    margin-bottom: 1rem;
    
  }
  .recentepisodes__card__content--heading{
      font-size: 1rem;
  }
  .recentepisodes__card--image{
    width: "174";
    object-fit:cover;
    height: "174";
  }

}
 

@media (max-width: 1700px)
{

}

@media (min-width: 481px) and (max-width: 767px) {
.recentepisodes__card--image{
  width: 70%;

}
}


@media (max-width: 480px)  {
  .recentepisodes__card--image{
  width: 70%;
  
}
}
